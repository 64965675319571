<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
        <div class="row">
            <div class="col-12">
                <file-input class="c-file-input" ref="file" label="Banner Image*" v-model="model.banner_image"
                            :disabled="loading" name="Banner Image">
                </file-input>
            </div>
            <div class="col-12">
                <validated-input label="Banner Text 1*" v-model="model.banner_text_1"
                                 :rules="{required: true, max:25}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input label="Banner Text 2*" v-model="model.banner_text_2"
                                 :rules="{required: true, max:30}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input label="Banner Text 3*" v-model="model.banner_text_3"
                                 :rules="{required: true, max:70}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Order" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'AddBanner',

    data () {
        return {
            addUrl : urls.cms.banner.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Banner..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
