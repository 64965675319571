<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4>BANNER</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.addModal.show()" text="Add"/>
            </div>
        </div>
        <custom-vue-table title="" class="c-cms-table d-sm-none-lg-block" :show-search-box="false"
                               :fields="fields" :url="listUrl"
                               :per-page="10" ref="table">

            <template #banner_image="{rowData}">
                <div class="container-image fl-x-cc fl-a-c pos-r" @click="viewFile(rowData.banner_image)">
                    <img :src="rowData.banner_image" alt="Avatar" class="c-image-banner">
                    <div class="c-overlay ml-3">
                        <div class="c-icon cursor-pointer">
                            <i class="fa fa-eye"></i>
                        </div>
                    </div>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>

        <modal title="Add Banner" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
            <add-banner @success="formSuccess"/>
        </modal>

        <modal title="Edit Banner" ref="editModal" width="40r" header-color="primary" no-close-on-backdrop>
            <edit-banner :initial-data="editingItem" @success="formSuccess"/>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the banner <b v-html="deletingItem && deletingItem.name"></b>. Are you
                sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
        <banner-list-mobile-view @viewDetails="viewDetails" @setDelete="setDelete"
                                 class="d-lg-none" ref="bannerMobileView"></banner-list-mobile-view>
    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddBanner from './AddBanner';
import EditBanner from './EditBanner';
import BannerListMobileView from '../../../../views/cms/home/banner/BannerListMobileView';

export default {
    name       : 'Banner',
    components : { BannerListMobileView, EditBanner, AddBanner },
    data () {
        return {
            listUrl      : urls.cms.banner.list,
            addUrl       : urls.cms.banner.addEdit,
            deleteUrl    : urls.cms.banner.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name      : 'order',
                    sortField : 'order',
                    title     : 'Order'
                },
                {
                    name  : '__slot:banner_image',
                    title : 'Banner Image'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/banner/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            refs.table.refreshTable();
            refs.bannerMobileView.loadData();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.table.refreshTable();
            this.$refs.bannerMobileView.loadData();
        }
    }
};
</script>
